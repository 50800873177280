<template>
  <div class="bg-gray">
    <div class="mb-8 mt-6 bg-gray">
      <Loading v-if="loading" />
      <v-container>
        <v-row class="mt-2" ref="alert">
          <v-col cols="12" class="py-0">
            <h2 class="text-secondary header">สร้างออเดอร์</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="4">
            <v-select
              item-text="text"
              item-value="value"
              v-model="branch"
              label="สาขา"
              :items="branchItems"
              @change="changeBranch()"
            ></v-select>
          </v-col>
        </v-row>

        <div class="mt-16 mx-8">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  @keypress="$keypressNumber()"
                  v-model="form.tel"
                  label="เบอร์โทร"
                  type="tel"
                  maxlength="10"
                  :rules="[notEmptyRules]"
                  @keydown="getMemberKeypress()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="form.email" label="อีเมล"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="member ? true : false"
                  v-model="form.firstname"
                  label="ชื่อจริง"
                  :rules="[notEmptyRules]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="member ? true : false"
                  v-model="form.lastname"
                  label="นามสกุล"
                  :rules="[notEmptyRules]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :disabled="!validTextFieldRequired"
                  v-model="form.type"
                  :items="typeItems"
                  item-text="text"
                  item-value="value"
                  no-data-text="ไม่พบข้อมูล"
                  label="ประเภท"
                  @input="getSchedule()"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  :disabled="!validTextFieldRequired"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="!validTextFieldRequired"
                      v-model="date"
                      label="วันที่จอง"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    event-color="red"
                    v-model="date"
                    locale="th"
                    :events="fullEvents"
                    :min="nowDate"
                    @input="getSchedule()"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  :disabled="!validTextFieldRequired"
                  v-model="form.scheduleId"
                  no-data-text="ไม่พบข้อมูล"
                  :items="scheduleItems"
                  item-text="text"
                  item-value="value"
                  :rules="[notEmptyRules]"
                  label="รอบ"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!validTextFieldRequired || !validRoundRequired"
                  v-model.number="form.youngQty"
                  label="จำนวนเด็ก"
                  type="number"
                  @keypress="$keypressNumber()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!validTextFieldRequired || !validRoundRequired"
                  v-model.number="form.studentQty"
                  label="จำนวนนักเรียน"
                  type="number"
                  @keypress="$keypressNumber()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!validTextFieldRequired || !validRoundRequired"
                  v-model.number="form.adultQty"
                  label="จำนวนผู้ใหญ่"
                  type="number"
                  @keypress="$keypressNumber()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!validTextFieldRequired || !validRoundRequired"
                  v-model.number="form.seniorQty"
                  label="จำนวนผู้สูงอายุ"
                  type="number"
                  @keypress="$keypressNumber()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="text-secondary header mb-2">สรุปรายการ</h3>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left font-table">ราคา</th>
                          <th class="text-left font-table">เด็ก</th>
                          <th class="text-right font-table">
                            {{
                              youngPrice && form.youngQty
                                ? formatCurrency(youngPrice * form.youngQty)
                                : 0
                            }}
                          </th>
                          <th class="text-left font-table">บาท</th>
                        </tr>
                        <tr>
                          <th class="text-left font-table"></th>
                          <th class="text-left font-table">นักเรียน</th>
                          <th class="text-right font-table">
                            {{
                              studentPrice && form.studentQty
                                ? formatCurrency(studentPrice * form.studentQty)
                                : 0
                            }}
                          </th>
                          <th class="text-left font-table">บาท</th>
                        </tr>
                        <tr>
                          <th class="text-left font-table"></th>
                          <th class="text-left font-table">ผู้ใหญ่</th>
                          <th class="text-right font-table">
                            {{
                              adultPrice && form.adultQty
                                ? formatCurrency(adultPrice * form.adultQty)
                                : 0
                            }}
                          </th>
                          <th class="text-left font-table">บาท</th>
                        </tr>
                        <tr>
                          <th class="text-left font-table"></th>
                          <th class="text-left font-table">ผู้สูงอายุ</th>
                          <th class="text-right font-table">
                            {{
                              seniorPrice && form.seniorQty
                                ? formatCurrency(seniorPrice * form.seniorQty)
                                : 0
                            }}
                          </th>
                          <th class="text-left font-table">บาท</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th class="text-left font-table">
                            ใช้แต้ม ({{
                              member && member.point
                                ? formatCurrency(member.point)
                                : 0
                            }}
                            แต้ม)
                          </th>
                          <th class="text-right">
                            <v-text-field
                              :disabled="
                                (member && member.point <= 0) || !member
                              "
                              @keypress="$keypressNumber()"
                              v-model="usePoint"
                              class="centered-input"
                              color="grey lighten-43"
                              single-line
                            />
                          </th>
                          <th class="text-left font-table">แต้ม</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th class="text-left font-table">ใช้แพ็กเกจ</th>
                          <th class="text-left font-table">
                            <v-select
                              :disabled="!member"
                              v-model="form.memberPackageId"
                              class="centered-input"
                              :items="packagesItems"
                              item-text="text"
                              item-value="value"
                              no-data-text="ไม่พบข้อมูล"
                            >
                              <template v-slot:selection="{ item }">
                                <span
                                  class="d-flex justify-end"
                                  style="width: 100%"
                                >
                                  {{ item.text }}
                                </span>
                              </template>
                            </v-select>
                          </th>
                        </tr>
                        <tr>
                          <th></th>
                          <th class="text-left font-table">ส่วนลดแพ็กเกจ</th>
                          <th class="text-right font-table">
                            -
                            {{
                              discountPackage
                                ? formatCurrency(discountPackage)
                                : 0
                            }}
                          </th>
                          <th class="text-left font-table">บาท</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th class="text-left font-table">รวม</th>
                          <th class="text-right font-table">
                            {{ total ? formatCurrency(total) : 0 }}
                          </th>
                          <th class="text-left font-table">บาท</th>
                        </tr>
                        <tr>
                          <th class="text-left font-table">
                            ช่องทางการชำระเงิน
                          </th>
                          <th></th>
                          <th class="text-left font-table">
                            <v-select
                              v-model="form.channelPayment"
                              :items="typePaymentItems"
                              item-text="text"
                              item-value="value"
                              no-data-text="ไม่พบข้อมูล"
                              class="centered-input"
                            >
                              <template v-slot:selection="{ item }">
                                <span
                                  class="d-flex justify-end"
                                  style="width: 100%"
                                >
                                  {{ item.text }}
                                </span>
                              </template>
                            </v-select>
                          </th>
                        </tr>
                      </thead>
                    </template>
                  </v-simple-table>
                  <div class="text-right mt-8">
                    <v-btn
                      v-if="!isSave"
                      class="mt-2 mr-2"
                      color="dark"
                      dark
                      :disabled="
                        !validateText || (member && usePoint > member.point)
                      "
                      @click="onSaveOrder()"
                      >บันทึกข้อมูล</v-btn
                    >
                    <v-btn
                      class="mt-2 mr-2"
                      color="dark"
                      dark
                      v-if="isSave"
                      @click="resetForm()"
                      >เพิ่มข้อมูลใหม่</v-btn
                    >
                    <v-btn v-print="printObj" class="mt-2" color="dark" dark
                      >พิมพ์สายรัดข้อมือ</v-btn
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div id="printArea">
            <div
              style="padding-top: 27rem"
              v-for="item in countAllPrint"
              :key="item"
              class="print"
            >
              <div class="rotated">
                <!-- <p>{{ item.firstname }}</p> -->
                <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
              </div>
              <div>
                <!-- <img
                      style="width 6rem; height: 6rem ; margin-top: 4rem;"
                      :src="order.qrcode"
                    /> -->
              </div>
              <div style="margin-top: 5rem" class="rotated">
                <span class="text-center">
                  {{ formatDate(chooseSchedule.date) }}
                </span>
                <div>
                  {{
                    formatSchedule(
                      chooseSchedule.startTime,
                      chooseSchedule.endTime
                    )
                  }}
                </div>
              </div>
              <div class="rotated">
                <img
                  style="width 5rem; height: 5rem ; margin-left: 4rem;"
                  src="/svg/PlanToys_Logo-01.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import axios from "../../utils/axios";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      menu2: false,
      isSave: false,
      date: dayjs().format("YYYY-MM-DD"),
      schedule: [],
      scheduleItems: [],
      packages: [],
      packagesItems: [],
      fullEvents: [],
      member: null,
      usePoint: 0,
      moneyToPoint: [{ money: 0, point: 0 }],
      pointToMoney: [{ point: 0, money: 0 }],
      printObj: {
        id: "printArea",
      },
      branch: "",
      form: {
        firstname: "",
        lastname: "",
        tel: "",
        email: "",
        youngQty: 0,
        studentQty: 0,
        adultQty: 0,
        seniorQty: 0,
        type: "normal",
        scheduleId: "",
        memberPackageId: null,
        branch: "",
        total: 0,
        point: 0,
        discount: 0,
        channelPayment: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
    validateText() {
      if (
        !this.form.youngQty &&
        !this.form.studentQty &&
        !this.form.adultQty &&
        !this.form.seniorQty
      ) {
        return false;
      }
      return true;
    },
    discountPromotion() {
      if (!this.useDiscount && this.schedule.options) {
        const { total } = this.order.price;
        const result = (total * this.promotion) / 100;
        return result;
      }
      return 0;
    },
    notEmptyRules() {
      return (v) => !!v || "กรุณากรอกข้อมูล";
    },
    nowDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    countAllPrint() {
      let {
        form: { studentQty, adultQty, seniorQty },
      } = this;
      studentQty = parseInt(studentQty) || 0;
      adultQty = parseInt(adultQty) || 0;
      seniorQty = parseInt(seniorQty) || 0;
      return studentQty + adultQty + seniorQty;
    },
    validRoundRequired() {
      if (this.form.scheduleId) {
        return true;
      }
      return false;
    },
    validTextFieldRequired() {
      if (this.form.firstname && this.form.lastname && this.form.tel) {
        return true;
      }
      return false;
    },
    typeItems() {
      return [
        { text: "ปกติ", value: "normal" },
        { text: "กิจกรรม", value: "event" },
      ];
    },
    typePaymentItems() {
      return [
        { text: "เงินสด", value: 1 },
        { text: "บัตรเครดิต", value: 2 },
        { text: "โอนจ่าย", value: 3 },
      ];
    },
    chooseSchedule() {
      if (this.schedule && this.schedule.length > 0) {
        const filter = this.schedule.filter(
          (item) => item.scheduleId === this.form.scheduleId
        );
        return filter[0];
      }
      return [];
    },
    choosePackage() {
      if (this.packages && this.packages.length > 0) {
        const filter = this.packages.filter(
          (item) => item.memberPackageId === this.form.memberPackageId
        );
        return filter[0];
      }
      return [];
    },

    youngPrice() {
      if (this.chooseSchedule && this.chooseSchedule.options) {
        return this.chooseSchedule.options.price.young;
      }
      return 0;
    },
    studentPrice() {
      if (this.chooseSchedule && this.chooseSchedule.options) {
        return this.chooseSchedule.options.price.student;
      }
      return 0;
    },
    adultPrice() {
      if (this.chooseSchedule && this.chooseSchedule.options) {
        return this.chooseSchedule.options.price.adult;
      }
      return 0;
    },
    seniorPrice() {
      if (this.chooseSchedule && this.chooseSchedule.options) {
        return this.chooseSchedule.options.price.senior;
      }
      return 0;
    },
    priceTotal() {
      let priceTotal =
        this.youngPrice * this.form.youngQty +
        this.studentPrice * this.form.studentQty +
        this.adultPrice * this.form.adultQty +
        this.seniorPrice * this.form.seniorQty;
      if (this.form.studentQty > 0 && this.form.adultQty > 0) {
        priceTotal = priceTotal - (this.youngPrice + this.adultPrice);
      }
      return priceTotal - 100;
    },
    total() {
      let priceTotal =
        this.youngPrice * this.form.youngQty +
        this.studentPrice * this.form.studentQty +
        this.adultPrice * this.form.adultQty +
        this.seniorPrice * this.form.seniorQty;

      if (this.branch === "sathorn") {
        if (this.form.memberPackageId) {
          let maxPriceYoung = Math.max(this.youngPrice, this.studentPrice);
          if (
            this.youngPrice &&
            this.youngPrice > 0 &&
            this.studentPrice &&
            this.studentPrice > 0 &&
            this.form.youngQty > 0 &&
            this.form.studentQty > 0
          ) {
            maxPriceYoung = Math.min(this.youngPrice, this.studentPrice);
          }
          if (
            (!this.youngPrice || this.youngPrice <= 0) &&
            this.form.youngQty <= 0
          ) {
            maxPriceYoung = this.studentPrice;
          }
          if (
            (!this.studentPrice || this.studentPrice <= 0) &&
            this.form.studentQty <= 0
          ) {
            maxPriceYoung = this.youngPrice;
          }
          if (
            (!this.studentPrice || this.studentPrice <= 0) &&
            this.form.youngQty <= 0 &&
            this.form.studentQty > 0
          ) {
            maxPriceYoung = 0;
          }
          if (
            (!this.youngPrice || this.youngPrice <= 0) &&
            this.form.studentQty <= 0 &&
            this.form.youngQty > 0
          ) {
            maxPriceYoung = 0;
          }

          // Adult
          let minPriceAdult = Math.max(this.adultPrice, this.seniorPrice);
          if (
            this.adultPrice &&
            this.adultPrice > 0 &&
            this.seniorPrice &&
            this.seniorPrice > 0
          ) {
            minPriceAdult = Math.min(this.adultPrice, this.seniorPrice);
            if (this.form.adultQty <= 0) {
              minPriceAdult = this.seniorPrice;
            }
            if (this.form.seniorQty <= 0) {
              minPriceAdult = this.adultPrice;
            }
          }
          if (
            (!this.adultPrice || this.adultPrice <= 0) &&
            this.form.adultQty <= 0
          ) {
            minPriceAdult = this.seniorPrice;
          }
          if (
            (!this.seniorPrice || this.seniorPrice <= 0) &&
            this.form.seniorQty <= 0
          ) {
            minPriceAdult = this.adultPrice;
          }
          if (
            (!this.adultPrice || this.adultPrice <= 0) &&
            this.form.seniorQty <= 0 &&
            this.form.adultQty > 0
          ) {
            minPriceAdult = 0;
          }
          if (
            (!this.seniorPrice || this.seniorPrice <= 0) &&
            this.form.adultQty <= 0 &&
            this.form.seniorQty > 0
          ) {
            minPriceAdult = 0;
          }
          const priceYoungAndAdult = maxPriceYoung + minPriceAdult;
          const result = priceTotal - priceYoungAndAdult;
          if (
            (this.form.youngQty <= 0 && this.form.studentQty <= 0) ||
            (this.form.adultQty <= 0 && this.form.seniorQty <= 0)
          ) {
            return priceTotal;
          }
          if (result <= 0) return 0;
          return result;
        }

        const youngAndStudent = this.form.youngQty + this.form.studentQty;
        const countAudult2 = this.form.adultQty - youngAndStudent;
        if (countAudult2 <= 0) {
          const seinorSubCountAudult =
            this.form.seniorQty - Math.abs(countAudult2);
          if (seinorSubCountAudult <= 0) {
            priceTotal =
              this.form.youngQty * this.youngPrice +
              this.form.studentQty * this.studentPrice;
          }
          if (seinorSubCountAudult > 0) {
            priceTotal =
              this.form.youngQty * this.youngPrice +
              this.form.studentQty * this.studentPrice +
              seinorSubCountAudult * this.seniorPrice;
          }
        }
        if (countAudult2 > 0) {
          const price = this.adultPrice * Math.abs(countAudult2);
          const seniorPrice = this.seniorPrice * this.form.seniorQty;
          priceTotal =
            price +
            seniorPrice +
            this.form.youngQty * this.youngPrice +
            this.form.studentQty * this.studentPrice;
        }
      }
      if (this.discount || this.discountPackage) {
        const t = priceTotal - this.discountPackage || 0;
        const total = t - this.discount.money;
        const a = 100 - 0;
        const result = (total * a) / 100;
        if (result < 0) return 0;
        return result;
      }

      return priceTotal;
    },
    discount() {
      const condition = true;
      if (condition) {
        const closest = this.pointToMoney.reduce((prev, curr) => {
          const a =
            Math.abs(curr.point - this.usePoint) <
              Math.abs(prev.point - this.usePoint) &&
            curr.point <= this.usePoint
              ? curr
              : prev;
          return a;
        });
        if (this.usePoint < closest.point) {
          return { money: 0, point: 0 };
        }
        if (this.usePoint > this.point) {
          return { money: 0, point: 0 };
        }
        return { money: closest.money, point: closest.point };
      }
      return { money: 0, point: 0 };
    },
    discountPackage() {
      if (this.choosePackage && this.choosePackage.package) {
        if (this.chooseSchedule && this.chooseSchedule.options) {
          // if (
          //   this.choosePackage.package.minPrice <
          //   this.chooseSchedule.options.price.adult
          // ) {
          //   return this.choosePackage.package.minPrice;
          // }
          // return this.chooseSchedule.options.price.adult;
          let priceTotal =
            this.youngPrice * this.form.youngQty +
            this.studentPrice * this.form.studentQty +
            this.adultPrice * this.form.adultQty +
            this.seniorPrice * this.form.seniorQty;
          return priceTotal - this.total;
        }
      }
      return 0;
    },
  },
  async mounted() {
    if (this.info && this.info.profile) {
      const branch =
        this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
      this.branch = branch;
    }
    await this.getSchedule();
    await this.getSettingPoint();
  },
  methods: {
    async changeBranch() {
      try {
        await this.getSchedule();
        await this.getSettingPoint();
      } catch (error) {
        console.error(error);
      }
    },
    async getMemberKeypress() {
      try {
        setTimeout(async () => {
          if (this.form.tel.length === 0) {
            this.member = null;
            this.form.firstname = "";
            this.form.lastname = "";
            return;
          }
          if (this.form.tel.length === 10) {
            const {
              data: { data },
            } = await axios(`/member/tel/${this.form.tel}`);
            if (data && data.member) {
              this.member = data.member;
              this.form.firstname = data.member.firstname;
              this.form.lastname = data.member.lastname;
              this.form.email = data.member.email;
              this.getMemberPackage();
            } else {
              this.member = null;
              this.form.firstname = "";
              this.form.email = "";
              this.form.lastname = "";
            }
          }
        }, 100);
      } catch (error) {
        this.member = null;
        this.form.firstname = "";
        this.form.lastname = "";
      }
    },
    async getScheduleMonth() {
      try {
        // const { data } = await axios({
        //   method: "GET",
        //   url: `/schedule/date/month?month=${dayjs().format(
        //     "M"
        //   )}&year=${dayjs().format("YYYY")}&branch=${
        //     this.selected.branch
        //   }&type=${this.scheduleType}`,
        // });
        const { data } = await axios({
          method: "GET",
          url: `/schedule/date/month?branch=${this.branch}&type=${this.form.type}`,
        });

        const arr = [];

        for (let i = 0; i < data?.additional?.available?.length; i++) {
          arr.push(data.additional.available[i]);
        }
        for (let i = 0; i < data?.additional?.full?.length; i++) {
          arr.push(data.additional.full[i]);
        }
        this.fullEvents = arr;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingButton = false;
      }
    },
    async getSchedule() {
      try {
        this.menu2 = false;
        this.scheduleItems = [];
        const {
          data: { data },
        } = await axios.get(
          `/schedule/date/search?date=${this.date}&branch=${this.branch}&type=${this.form.type}`
        );
        for (let i = 0; i < data.length; i++) {
          this.scheduleItems.push({
            text: `${data[i].startTime} น. - ${data[i].endTime} น. (จองแล้ว ${data[i].slot})`,
            value: data[i].scheduleId,
          });
        }
        if (this.scheduleItems.length > 0) {
          this.form.scheduleId = this.scheduleItems[0].value;
        } else {
          this.form.scheduleId = "";
        }
        this.schedule = data;
        await this.getScheduleMonth();
      } catch (error) {
        console.error(error);
      }
    },
    async getMemberPackage() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `/package-member/${this.member.memberId}/member?package.branch=${this.branch}`
        );
        const packagesFilter = data.filter((item) => {
          const minute = dayjs(item.package.expired).diff(dayjs(), "minute");
          return item.package.items > 0 && minute > 0;
        });
        const arr = [{ text: "ไม่ใช้แพ็กเกจ", value: null }];
        for (let i = 0; i < packagesFilter.length; i++) {
          arr.push({
            text: `${packagesFilter[i].package.title} (มูลค่า ${packagesFilter[i].package.minPrice} บาท) (คงเหลือ ${packagesFilter[i].package.items})`,
            value: packagesFilter[i].memberPackageId,
          });
        }
        this.packagesItems = arr;
        if (data) {
          this.packages = data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getSettingPoint() {
      try {
        const {
          data: { data },
        } = await axios.get(`/setting/point?branch=${this.branch}`);
        this.moneyToPoint = data.moneyToPoint;
        this.pointToMoney = data.pointToMoney;
      } catch (error) {
        console.error(error);
      }
    },
    async onSaveOrder() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          this.form.total = this.total;
          this.form.point = this.discount.point;
          this.form.branch = this.branch;
          this.form.youngQty = this.form.youngQty ? this.form.youngQty : 0;
          this.form.adultQty = this.form.adultQty ? this.form.adultQty : 0;
          this.form.studentQty = this.form.studentQty
            ? this.form.studentQty
            : 0;
          this.form.seniorQty = this.form.seniorQty ? this.form.seniorQty : 0;
          await axios.post("/order/create-order", this.form);
          this.alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
          this.isSave = true;
        }
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ไม่สามารถดำเนินการได้");
      } finally {
        this.loading = false;
      }
    },
    async resetForm() {
      try {
        this.isSave = false;
        this.$refs.form.resetValidation();
        this.form = {
          firstname: "",
          lastname: "",
          tel: "",
          youngQty: 0,
          studentQty: 0,
          adultQty: 0,
          seniorQty: 0,
          type: "normal",
          scheduleId: "",
          memberPackageId: null,
          branch: "",
          total: 0,
          point: 0,
          discount: 0,
          channelPayment: 1,
        };
        await this.getSchedule();
      } catch (error) {
        console.error(error);
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    wrapWord(val) {
      if (val.length > 15) {
        return `${val.slice(0, 15)}...`;
      }
      return val;
    },
    formatDate(val) {
      return dayjs(val).locale("en").format("DD.MMM.YY");
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: right;
}
.font-table {
  font-size: 16px !important;
}
#printArea {
  display: none;
}

.rotated {
  transform: rotate(90deg);
}

.print {
  height: 28cm;
  /* height: 14cm; */
}
@media print {
  .print {
    height: 100vh;
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    overflow: hidden;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  #printArea {
    display: block;
  }
  .rotated {
    transform: rotate(90deg);
  }
  footer {
    page-break-after: always;
  }
}
</style>
